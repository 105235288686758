<script>
import appConfig from "@/app.config";
import PageHeader from "@/components/page-header";
import draggable from "vuedraggable";
import CalendarApiService from "@/services/calendarService";
import DeletionModal from "../../../../components/widgets/deletion-modal.vue"

/**
 * Dashboard Component
 */
export default {
  page: {
    title: "progress",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    PageHeader,
    draggable,
    DeletionModal
  },
  data() {
    return {
      title: "Progress",
      isLoading: false,
      items: [
        {
          text: "progress",
          href: "/progress",
        },
        {
          text: "",
          active: true,
        },
      ],
      progress: [],
      editTemplate: {
        description: null,
      },
      newTemplate: {
        progress: null,
      },
      progressIdPublic: null,
      progressIndex: null
    };
  },
  created() {
    //Load progress
    this.isLoading = true;
    CalendarApiService.getAllProgressListView().then((response) => {
      this.progress = response.data.data;

      for (let p of this.progress) {
        let ut = p.unitType;
        if (ut) {
          ut.translate = this.$t("general." + ut.name.toLowerCase());
        }
      }

      //sort by display order
      this.progress.sort((a, b) =>
        a.order > b.order ? 1 : b.order > a.order ? -1 : 0
      );

      this.isLoading = false;
    });
  },
  watch: {
    locale: function () {
      //translate unit type
      for (let p of this.progress) {
        let ut = p.unitType;
        if (ut) {
          ut.translate = this.$t("general." + ut.name.toLowerCase());
        }
      }
    },
  },
  computed: {
    locale: function () {
      return this.$i18n.locale;
    },
  },
  mounted() {},
  methods: {
    showDeletionModal(idPublic, index) {
      this.$refs.progressDeleteModal.show().then(() => {
        this.isLoading = true;
        CalendarApiService.softDeleteProgress(idPublic).then(() => {
          this.progress.splice(index, 1);
          this.isLoading = false;
        })
      })
    },
    editProgress(progressId) {
      this.$router.push({
        name: "editProgress",
        params: { progressId: progressId },
      });
    },
    createProgress() {
      this.$router.push({ name: "createProgress" });
    },
    onCloseTemplateStepDialog(data) {
      window.console.log(data);
    },
    updateDisplayOrder(ev) {
      if (ev.moved) {
        let progressId = ev.moved.element.id;
        let progressIdBefore;
        if (ev.moved.newIndex == 0) {
          progressIdBefore = null;
        } else {
          let indexBefore = ev.moved.newIndex - 1;
          progressIdBefore = this.progress[indexBefore].id;
        }

        let model = {
          progressId: progressId,
          previousProgressId: progressIdBefore,
        };

        CalendarApiService.updateProgressOrder(model);
      }
    },
  },
};
</script>

<template>
  <Layout :contentPadding="true">
    <PageHeader :title="$t('general.progress')" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div class="col-12 mb-3" style="display: flex">
                <p class="card-title-desc" style="margin-bottom: 0">
                  {{ $t("general.progressListDescription") }}
                </p>
                <b-button
                  style="margin-left: auto"
                  @click="createProgress()"
                  variant="primary"
                  >{{ $t("general.addProgress") }}</b-button
                >
              </div>
            </div>

            <div ref="progressTable" class="table-responsive">
              <loading
                :active.sync="isLoading"
                :can-cancel="false"
                color="#f1b44c"
                :width="70"
                :height="70"
                loader="dots"
                :is-full-page="false"
              >
              </loading>
              <table class="table mb-0 table-hover">
                <thead class="thead-light">
                  <tr>
                    <th style="width: 70px; text-align: center">#</th>
                    <th>{{ $t("general.name") }}</th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.color") }}
                    </th>
                    <!-- <th style="width:150px; text-align:center;">{{$t('general.unitType')}}</th> -->
                    <th style="width: 150px; text-align: center">
                      {{ $t("general.referenceDate") }}
                    </th>
                    <th style="width: 150px; text-align: center">
                      {{ $t("general.origin") }}
                    </th>
                    <th style="width: 100px; text-align: center">
                      {{ $t("general.action") }}
                    </th>
                  </tr>
                </thead>
                <draggable
                  @change="updateDisplayOrder($event, progress)"
                  v-model="progress"
                  tag="tbody"
                >
                  <tr
                    :class="{ originPlanif: p.origin == 'Planification' }"
                    v-for="(p, index) in progress"
                    :key="index"
                    style="cursor: move"
                  >
                    <td style="text-align: center">{{ index + 1 }}</td>
                    <td>{{ p.name }}</td>
                    <td>
                      <div
                        :style="{ background: p.colorHexa }"
                        style="
                          margin: 0 auto;
                          width: 20px;
                          height: 20px;
                          border-radius: 5px;
                        "
                      ></div>
                    </td>
                    <!-- <td style="text-align:center;">{{p.unitType.translate}}</td> -->
                    <td style="text-align: center">
                      {{
                        $t(
                          (
                            "general." + p.backSchedulingReferenceDate
                          ).toLowerCase()
                        )
                      }}
                    </td>
                    <td style="text-align: center">{{ $t("general." + p.origin) }}</td>
                    <td style="text-align: center">
                      <b-dropdown
                        dropleft
                        size="lg"
                        class="card-drop"
                        variant="link"
                        toggle-class="text-decoration-none p-0"
                        no-caret
                      >
                        <template #button-content>
                          <i class="fas fa-ellipsis-h" />
                        </template>

                        <b-dropdown-item @click="editProgress(p.id)">
                          <i class="fas fa-pencil-alt text-success mr-1"></i>
                          {{ $t("general.edit") }}
                        </b-dropdown-item>

                        <b-dropdown-item
                          v-if="p.origin == 'Planification'"
                          @click="showDeletionModal(p.id, index)"
                        >
                          <i class="fas fa-trash-alt text-danger mr-1"></i>
                          {{ $t("general.delete") }}
                        </b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </draggable>
              </table>
            </div>
          </div>
        </div>
      </div>
      <DeletionModal ref="progressDeleteModal" :content="this.$t('general.deleteProgressContent')" :title="this.$t('general.deleteProgressTitle')"/>
    </div>
  </Layout>
</template>
